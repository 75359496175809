import React from "react"
import Nav from "../../../../src/components/Nav"
import HeroSimple from "../../../../src/components/HeroSimple"
import SEO from "../../../../src/components/seo/SEO"
import Contact from "../../../../src/components/Contact"
import Footer from "../../../../src/components/Footer"
import { graphql } from 'gatsby'

const Template = ({ data, pageContext }) => {
  const html = data.markdownRemark.html;
  const title = data.markdownRemark.frontmatter.title;
  return (
    <>
      <SEO />
      <div className="flex">
        <section title="content" className="w-full">
          <div className="gradient">
            <Nav active="faq" />
            <HeroSimple
              heading={title}
              subText={"Page FAQ"}
            />
          </div>
          <div className="container mx-auto mb-24">
            <article className=" p-4 md:p-12 lg:p-12 shadow-md">
              <div
                className="markdown-content px-8 lg:px-0 w-full"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </article>
          </div>
        </section>
      </div>

      <Contact />
      <Footer image={data.footerImage.childImageSharp.fluid} />
    </>
  )
}


export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        title
      }
    }
    footerImage: file(relativePath: { eq: "logo.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Template
